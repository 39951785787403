import gql from "graphql-tag";

export const USER_FIELDS = gql`fragment UserFields on UserType { 
  id username email name image isAdmin canRunPipelines created memberships {
    id name slug description isAdmin
    admins { id username name image }
    members { id username name image }
  }
  notifications(first: 10) { unreadCount count pageInfo { endCursor } edges { node {
    id category created hasRead resolved text
    user { id username name image }
    group { id slug name }
  } } }
}`;

export const TOKEN = gql`{ accessToken }`;

export const USER = gql`{ me { ...UserFields } } ${USER_FIELDS}`;

export const HOME = gql`{
  publicPapers(first: 4) { edges { node { id title year journal } } }
  pipelineCount userCount sampleCount executionCount
  sampleCounts
  news(first: 5) { edges { node { id headline date category } } }
}`

export const NOTIFICATIONS = gql`query notifications($after: String) { me {
  notifications(after: $after first: 10) { pageInfo { endCursor } edges { node {
    id category created hasRead resolved text
    user { id username name image }
    group { id slug name }
  } } }
} }`

export const PUBLIC_USER = gql`query user($username: String!) { 
  user(username: $username) { id name username image created memberships {
    id name slug description
  } }
}`;

export const POSSIBLE_USERS = gql`query possibleUsers(
  $text: String! $first: Int $last: Int
) {
  searchUsers(
    text: $text first: $first last: $last
  ) { count edges { node { id name username image } } }
}`;

export const POSSIBLE_GROUPS = gql`query possibleGroups(
  $text: String! $first: Int $last: Int
) {
  searchGroups(
    text: $text first: $first last: $last
  ) { count edges { node { id name slug } } }
}`;

export const PUBLIC_USER_PROJECTS = gql`query user(
  $username: String! $first: Int! $last: Int!
) { 
  user(username: $username) {
    id publicProjects(first: $first last: $last) { count edges { node {
      id name description owner { id name }
    } } }
  }
}`;

export const PUBLIC_USER_SAMPLES = gql`query user(
  $username: String! $first: Int! $last: Int!
) { 
  user(username: $username) {
    id publicSamples(first: $first last: $last) { count edges { node {
      id created name category owner { id name }
    } } }
  }
}`;

export const PUBLIC_USER_DATA = gql`query user(
  $username: String! $first: Int! $last: Int!
) { 
  user(username: $username) {
    id publicData(first: $first last: $last) { count edges { node {
      id filename size created owner { id name username }
      upstreamProcessExecution { id processName }
    } } }
  }
}`;

export const UPLOAD_INFO = gql`{
  organisms { id name }
  ownedProjects { edges { node { id name } } }
}`;

export const PIPELINES = gql`{ pipelineCategories {
  id name description subcategories { id name description pipelines {
    id name version { id description } executionCount preparesGenome isNfcore
  } } 
} }`;

export const PIPELINE = gql`query pipeline($id: ID! $version: ID) {
  pipeline(id: $id) {
    id name preparesGenome versions(active: true) { id name } isNfcore
    version(id: $version) {
      id name longDescription schema upstreamPipelineVersions { id name }
    }
  }
  nextflowVersions
  genomes {
    id name organism { id name } fasta { id filename } gtf { id filename }
    data { id filetype filename }
  }
}`;

export const GROUP = gql`query group($slug: String!) { group(slug: $slug) {
  id slug name description created isAdmin
  members { id name username image } admins { id username name image }
} }`;

export const DATA = gql`query data($id: ID!) { data(id: $id) {
  id filename filetype size category created isDirectory isBinary private contents
  forcedPublicProjectId forcedPublicSampleId forcedPublicExecutionId
  canEdit canShare
  upstreamProcessExecution { id processName execution {
    id pipelineVersion { id pipeline { id name } }
  } }
  downstreamExecutions {
    id started created status finished identifier
    pipelineVersion { id name pipeline { id name } } owner { id name }
    genome { id name organism { id name } }
  }
  sample { id name } project { id name } owner { id username name image }
  genome { id name organism { name } }
  genomeFasta { id name organism { name } }
  genomeGtf { id name organism { name } }

  usersWithReadAccess: users(permission: 1) { id username name image }
  usersWithEditAccess: users(permission: 2) { id username name image }
  usersWithShareAccess: users(permission: 3) { id username name image }
  groupsWithReadAccess: groups(permission: 1) { id slug name }
  groupsWithEditAccess: groups(permission: 2) { id slug name }
  groupsWithShareAccess: groups(permission: 3) { id slug name }

  usersWithIndirectReadAccess: users(permission: 1 indirect: true) { id username name image }
  usersWithIndirectEditAccess: users(permission: 2 indirect: true) { id username name image }
  usersWithIndirectShareAccess: users(permission: 3 indirect: true) { id username name image }
  groupsWithIndirectReadAccess: groups(permission: 1 indirect: true) { id slug name }
  groupsWithIndirectEditAccess: groups(permission: 2 indirect: true) { id slug name }
  groupsWithIndirectShareAccess: groups(permission: 3 indirect: true) { id slug name }

} }`;

export const DATA_CONTENTS = gql`query data($id: ID! $position: Int) {
  data(id: $id) { id contents(position: $position) }
}`;

export const EXECUTION = gql`query execution($id: ID!) { execution(id: $id) {
  id pipelineVersion { id name description schema pipeline { id name } } pid
  nextflowVersion forcedPublicProjectId forcedPublicSampleId canEdit canShare
  created started finished taskStarted taskFinished identifier private hasReport
  params dataParams sampleParams stdout stderr status dependent command log
  upstreamData { id filename } owner { id name username image }
  upstreamSamples { id name } genome { id name organism { id name } }
  sample { id name } project { id name }
  processExecutions {
    id identifier name processName started finished stdout stderr bash status exitCode
    upstreamData { id filename } upstreamSamples { id name } sample { id name }
    downstreamData { id filename filetype size created owner { name } isRemoved }
  }
  usersWithReadAccess: users(permission: 1) { id username name image }
  usersWithEditAccess: users(permission: 2) { id username name image }
  usersWithShareAccess: users(permission: 3) { id username name image }
  groupsWithReadAccess: groups(permission: 1) { id slug name }
  groupsWithEditAccess: groups(permission: 2) { id slug name }
  groupsWithShareAccess: groups(permission: 3) { id slug name }
  usersWithIndirectReadAccess: users(permission: 1 indirect: true) { id username name image }
  usersWithIndirectEditAccess: users(permission: 2 indirect: true) { id username name image }
  usersWithIndirectShareAccess: users(permission: 3 indirect: true) { id username name image }
  groupsWithIndirectReadAccess: groups(permission: 1 indirect: true) { id slug name }
  groupsWithIndirectEditAccess: groups(permission: 2 indirect: true) { id slug name }
  groupsWithIndirectShareAccess: groups(permission: 3 indirect: true) { id slug name }
} }`;

export const PROJECT = gql`query project($id: ID!) { project(id: $id) {
  id name private created description owner { id name username image }
  samples { id created name category owner { id name } organism { id name } }
  canEdit canShare publicIssues
  allExecutions {
    id started created status finished identifier
    pipelineVersion { id name pipeline { id name } } owner { id name }
    genome { id name organism { id name } }
  }
  allData {
    id filename size created owner { id name username }
    upstreamProcessExecution { id processName }
  }
  papers { id title year journal }
  usersWithReadAccess: users(permission: 1) { id username name image }
  usersWithEditAccess: users(permission: 2) { id username name image }
  usersWithShareAccess: users(permission: 3) { id username name image }
  groupsWithReadAccess: groups(permission: 1) { id slug name }
  groupsWithEditAccess: groups(permission: 2) { id slug name }
  groupsWithShareAccess: groups(permission: 3) { id slug name }
} }`;

export const SAMPLE = gql`query sample($id: ID!) {
  sample(id: $id) {
    id name private created category owner { id name username image } forcedPublicProjectId
    initialData { id created data { id filename size } } organism { id name }
    source { id name } purificationTarget { id name } project { id name }
    sourceText purificationTargetText publicIssues
    scientist pi organisation purificationAgent experimentalMethod condition
    sequencer comments fivePrimeBarcodeSequence threePrimeBarcodeSequence threePrimeAdapterName
    threePrimeAdapterSequence read1Primer read2Primer rtPrimer
    umiBarcodeSequence umiSeparator strandedness rnaSelectionMethod
    canEdit canShare geo ena pubmed
    executions {
      id started created status finished identifier
      pipelineVersion { id name pipeline { id name } } owner { id name }
      genome { id name organism { id name } }
    }
    downstreamExecutions {
      id started created status finished identifier
      pipelineVersion { id name pipeline { id name } } owner { id name }
      genome { id name organism { id name } }
    }
    data {
      id filename size created owner { id name username }
      upstreamProcessExecution { id processName }
    }
    usersWithReadAccess: users(permission: 1) { id username name image }
    usersWithEditAccess: users(permission: 2) { id username name image }
    usersWithShareAccess: users(permission: 3) { id username name image }
    groupsWithReadAccess: groups(permission: 1) { id slug name }
    groupsWithEditAccess: groups(permission: 2) { id slug name }
    groupsWithShareAccess: groups(permission: 3) { id slug name }
    usersWithIndirectReadAccess: users(permission: 1 indirect: true) { id username name image }
    usersWithIndirectEditAccess: users(permission: 2 indirect: true) { id username name image }
    usersWithIndirectShareAccess: users(permission: 3 indirect: true) { id username name image }
    groupsWithIndirectReadAccess: groups(permission: 1 indirect: true) { id slug name }
    groupsWithIndirectEditAccess: groups(permission: 2 indirect: true) { id slug name }
    groupsWithIndirectShareAccess: groups(permission: 3 indirect: true) { id slug name }
  }
  organisms { id name }
}`;

export const ORGANISM = gql`query organism($id: String!) { organism(id: $id) {
  id name latinName genomes {
    id name created
    fasta { id filename size } gtf { id filename size } data { id filename size }
  }
  samples { count edges { node {
    id created name category owner { id name } organism { id name }
  } } }
} }`;

export const ORGANISM_SAMPLES = gql`query organismSamples(
  $id: String! $first: Int $last: Int
) {
  organism(id: $id ) { id samples(first: $first last: $last) { count edges {
    node { id created name category owner { id name } organism { id name } }
  } } }
}`;

export const GENOME = gql`query genome($id: ID!) { genome(id: $id) {
  id name longName created organism { id name latinName }
  fasta { id filename size } gtf { id filename size } data { id filename size }
  executions {
    id started created status finished identifier owner { id name }
    pipelineVersion { id name pipeline { id name preparesGenome } }
    genome { id name organism { id name } }
  }
} }`;

export const POSSIBLE_DATA = gql`query searchData(
  $filetype: String! $filename: String! $category: Int $isSingle: Boolean
  $isUploaded: Boolean $isOwned: Boolean $first: Int $last: Int
) {
  searchData(
    filetype: $filetype filename: $filename category: $category isSingle: $isSingle
    isUploaded: $isUploaded isOwned: $isOwned first: $first last: $last
  ) { count edges { node {
    id filename created size owner { id name }
    upstreamProcessExecution { id processName }
    pair { id filename }
  } } }
}`;

export const POSSIBLE_EXECUTIONS = gql`query searchExecutions(
  $name: String! $pipelineVersions: [ID] $organism: ID $first: Int $last: Int
) {
  searchExecutions(
    name: $name pipelineVersions: $pipelineVersions organism: $organism
    first: $first last: $last
  ) { count edges { node {
    id identifier created owner { id name }
    pipelineVersion { id name pipeline { id name } }
    processExecutions { id processName downstreamData { id filename filetype } }
    genome {
      id name organism { id name } fasta { id filename } gtf { id filename }
      data { id filename filetype }
    }
  } } }
}`;

export const POSSIBLE_SAMPLES = gql`query searchSamples(
  $name: String! $organism: ID $categories: [String] $isOwned: Boolean
  $first: Int $last: Int
) {
  searchSamples(
    name: $name organism: $organism categories: $categories isOwned: $isOwned
    first: $first last: $last
  ) { count edges { node {
    id name created category owner { id name } organism { id name }
    scientist pi organisation purificationAgent experimentalMethod condition
    sequencer comments fivePrimeBarcodeSequence threePrimeBarcodeSequence threePrimeAdapterName
    threePrimeAdapterSequence read1Primer read2Primer rtPrimer
    umiBarcodeSequence umiSeparator strandedness rnaSelectionMethod
  } } }
}`;

export const POSSIBLE_SAMPLE_SOURCES = gql`query searchSampleSources(
  $name: String! $first: Int $last: Int
) {
  searchSampleSources(
    name: $name first: $first last: $last
  ) { count edges { node { id name } } }
}`;

export const POSSIBLE_SAMPLE_PURIFICATION_TARGETS = gql`query searchSamplePurificationTargets(
  $name: String! $first: Int $last: Int
) {
  searchSamplePurificationTargets(
    name: $name first: $first last: $last
  ) { count edges { node { id name } } }
}`;

export const PUBLIC_PROJECTS = gql`query publicProjects($first: Int $last: Int) {
  publicProjects(first: $first last: $last) { count edges { node {
    id name description owner { id name }
  } } }
}`;

export const USER_OWNED_PROJECTS = gql`query userOwnedProjects($first: Int $last: Int) {
  ownedProjects(first: $first last: $last) { count edges { node {
    id name description owner { id name }
  } } }
}`;

export const USER_SHARED_PROJECTS = gql`query userSharedProjects($first: Int $last: Int) {
  sharedProjects(first: $first last: $last) { count edges { node {
    id name description owner { id name }
  } } }
}`;

export const USER_OWNED_SAMPLES = gql`query userOwnedSamples($first: Int $last: Int) {
  ownedSamples(first: $first last: $last) { count edges { node {
    id created name category owner { id name } organism { id name }
  } } }
}`;

export const USER_SHARED_SAMPLES = gql`query userSharedSamples($first: Int $last: Int) {
  sharedSamples(first: $first last: $last) { count edges { node {
    id created name category owner { id name } organism { id name }
  } } }
}`;

export const USER_OWNED_EXECUTIONS = gql`query userOwnedExecutions($first: Int $last: Int) {
  ownedExecutions(first: $first last: $last) { count edges { node {
    id started created status finished identifier
    pipelineVersion { id name pipeline { id name } } owner { id name }
    genome { id name organism { id name } }
  } } }
}`;

export const USER_SHARED_EXECUTIONS = gql`query userSharedExecutions($first: Int $last: Int) {
  sharedExecutions(first: $first last: $last) { count edges { node {
    id started created status finished identifier
    pipelineVersion { id name pipeline { id name } } owner { id name username }
    genome { id name organism { id name } }
  } } }
}`;

export const USER_OWNED_DATA = gql`query userOwnedData($first: Int $last: Int) {
  ownedData(first: $first last: $last) { count edges { node {
    id filename size created owner { id name username }
    upstreamProcessExecution { id processName }
  } } }
}`;

export const USER_SHARED_DATA = gql`query userSharedData($first: Int $last: Int) {
  sharedData(first: $first last: $last) { count edges { node {
    id filename size created owner { id name username }
    upstreamProcessExecution { id processName }
  } } }
}`;

export const BULK_DOWNLOAD_JOB = gql`query bulkDownloadJob($id: ID!) {
  bulkDownloadJob(id: $id) { id finished }
}`;

export const GENOMES = gql`{ organisms {
  id name latinName latestGenome {
    id name created data { id filename size }
    fasta { id filename size } gtf { id filename size } 
  }
} }`;

export const QUICK_SEARCH = gql`query quickSearch($text: String!) {
  searchUsers(text: $text first: 10) { edges { node { id name username created } } }
  searchGroups(text: $text first: 10) { edges { node { id name slug description created } } }
  searchProjects(text: $text first: 10) { edges { node { id name description created } } }
  searchSamples(name: $text first: 10) { edges { node { id name created } } }
  searchData(filename: $text first: 10) { edges { node { id filename  created} } }
  searchExecutions(text: $text first: 10) { edges { node { id identifier created pipelineVersion {
    id pipeline { id name }
  } } } }
}`;

export const SEARCH_PROJECTS = gql`query searchProjects(
  $name: String $description: String $owner: String $first: Int $last: Int
) { searchProjects(
  name: $name description: $description owner: $owner first: $first last: $last
) { count edges { node  {
  id name description owner { id name }
} } } }`;

export const SEARCH_SAMPLES = gql`query searchSamples(
  $name: String $type: String $owner: String $first: Int $last: Int
) { searchSamples(
  name: $name sampleType: $type owner: $owner first: $first last: $last
) { count edges { node {
  id created name sampleType owner { id name } organism { id name }
} } } }`;

export const SEARCH_EXECUTIONS = gql`query searchExecutions(
  $name: String $status: String $owner: String $first: Int $last: Int
) { searchExecutions(
  name: $name status: $status owner: $owner first: $first last: $last
) { count edges { node  {
  id started created status finished identifier
  genome { id name organism { id name } }
  pipelineVersion { id pipeline { id name } } owner { id name username }
} } } }`;

export const SEARCH_DATA = gql`query searchData(
  $name: String $type: String $process: String $owner: String
  $sizeGt: Float $sizeLt: Float $first: Int $last: Int
) { searchData(
  filename: $name filetype: $type process: $process owner: $owner
  sizeLt: $sizeLt sizeGt: $sizeGt first: $first last: $last
) { count edges { node  {
  id filename size created owner { id name username }
  upstreamProcessExecution { id processName }
} } } }`;

export const PIPELINE_ADMIN = gql`{
  pipelineRepos { id path url originalUrl pipelines {
    id name order isNfcore isDemultiplex preparesGenome importsSamples
    subcategory { id name category { id name } }
    versions {
      id name git description longDescription created active path schemaPath
      private configPaths executionCount upstreamPipelineVersions { id name }
    }
  } }
  pipelineCategories { id name description subcategories { id name description } }
}`;

export const PIPELINE_CONFIG = gql`{
  pipelineConfigs { filename contents }
}`

export const GENOMES_ADMIN = gql`{ organisms {
  id name latinName genomes {
    id name longName created url executionCount data { id filename size }
    fasta { id filename size } gtf { id filename size } 
  }
} }`;

export const NEWS_ADMIN = gql`{ news { edges { node { id headline date category } } } }`;

export const USERS_ADMIN = gql`{
  admins { id username name }
  pipelineRunners { id username name }
}`;