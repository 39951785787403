import React, { useState } from "react";
import PropTypes from "prop-types";
import { PIPELINE_ADMIN } from "../queries";
import { parseError } from "../errors";
import { useMutation } from "@apollo/client";
import { CREATE_PIPELINE_VERSION, UPDATE_PIPELINE_VERSION } from "../mutations";
import Button from "./Button";

const PipelineVersionForm = props => {

  const { pipelineVersion, pipelineId, setShow, allGenomeVersions } = props;

  const [name, setName] = useState(null);
  const [git, setGit] = useState(null);
  const [isPrivate, setIsPrivate] = useState(null);
  const [description, setDescription] = useState(null);
  const [longDescription, setLongDescription] = useState(null);
  const [active, setActive] = useState(null);
  const [path, setPath] = useState(null);
  const [schemaPath, setSchemaPath] = useState(null);
  const [configPaths, setConfigPaths] = useState(null);
  const [upstreamPipelineVersions, setupstreamPipelineVersions] = useState(null);
  const [errors, setErrors] = useState(null);

  const nameText = name === null ? pipelineVersion ? pipelineVersion.name : "" : (name || "");
  const gitText = git === null ? pipelineVersion ? pipelineVersion.git : "" : (git || "");
  const isPrivateText = isPrivate === null ? pipelineVersion ? pipelineVersion.private : false : (isPrivate || false);
  const descriptionText = description === null ? pipelineVersion ? pipelineVersion.description : "" : (description || "");
  const longDescriptionText = longDescription === null ? pipelineVersion ? pipelineVersion.longDescription : "" : (longDescription || "");
  const activeText = active === null ? pipelineVersion ? pipelineVersion.active : false : (active || false);
  const pathText = path === null ? pipelineVersion ? pipelineVersion.path : "" : (path || "");
  const schemaPathText = schemaPath === null ? pipelineVersion ? pipelineVersion.schemaPath : "" : (schemaPath || "");
  const configPathsText = configPaths === null ? pipelineVersion ? pipelineVersion.configPaths : "" : (configPaths || "");
  const upstreamPipelineVersionsText = upstreamPipelineVersions === null ? pipelineVersion ? pipelineVersion.upstreamPipelineVersions.map(v => v.id) : [] : (upstreamPipelineVersions || []);

  const [createPipelineVersion, createPipelineVersionMutation] = useMutation(CREATE_PIPELINE_VERSION, {
    variables: {
      name: nameText, git: gitText, private: isPrivateText, description: descriptionText,
      longDescription: longDescriptionText, active: activeText, path: pathText,
      schemaPath: schemaPathText, configPaths: configPathsText,
      pipeline: pipelineId, upstreamPipelineVersions: upstreamPipelineVersionsText
    },
    refetchQueries: [{query: PIPELINE_ADMIN}],
    awaitRefetchQueries: true,
    onCompleted: () => setShow(false),
    onError: error => setErrors(parseError(error))
  });

  const [updatePipelineVersion, updatePipelineVersionMutation] = useMutation(UPDATE_PIPELINE_VERSION, {
    variables: {
      id: pipelineVersion?.id, name: nameText, git: gitText, private: isPrivateText,
      description: descriptionText, longDescription: longDescriptionText, active: activeText,
      path: pathText, schemaPath: schemaPathText, configPaths: configPathsText,
      upstreamPipelineVersions: upstreamPipelineVersionsText
    },
    refetchQueries: [{query: PIPELINE_ADMIN}],
    awaitRefetchQueries: true,
    onCompleted: () => setShow(false),
    onError: error => setErrors(parseError(error))
  });

  const onSubmit = e => {
    e.preventDefault();
    if (!pipelineVersion) createPipelineVersion();
    if (pipelineVersion) updatePipelineVersion();
  };

  const handleSelectChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions).map(o => o.value);
    setupstreamPipelineVersions(selectedOptions);
  };

  const loading = createPipelineVersionMutation.loading || updatePipelineVersionMutation.loading;

  const blockClass = "w-full mb-2";
  const checkBlockClass = "flex gap-1 items-center cursor-pointer";
  const labelClass = "text-[#37474F] font-medium text-2xs block cursor-pointer";
  const inputClass = "block bg-[#F3F3F3] text-[#3B59C3] rounded outline-none text-2xs px-2 py-1 w-full";

  return (
    <form onSubmit={onSubmit} className="px-2 pt-2 w-full border-t">
      <div className="grid grid-cols-2 gap-2">
        <div className={blockClass}>
          <label className={labelClass} htmlFor="name">Name</label>
          {errors && errors.validation?.name && (
            <div className="text-red-500 text-xs mb-2">{errors.validation.name[0]}</div>
          )}
          <input
            id="name"
            type="text"
            value={nameText}
            onChange={e => setName(e.target.value)}
            className={inputClass}
            autoComplete="off"
            required
          />
        </div>
        <div className={blockClass}>
          <label className={labelClass} htmlFor="git">Git</label>
          {errors && errors.validation?.git && (
            <div className="text-red-500 text-xs mb-2">{errors.validation.git[0]}</div>
          )}
          <input
            id="git"
            type="text"
            value={gitText}
            onChange={e => setGit(e.target.value)}
            className={inputClass}
            autoComplete="off"
            required
          />
        </div>
      </div>
      <div className={blockClass}>
        <label className={labelClass} htmlFor="schemaPath">Description</label>
        {errors && errors.validation?.description && (
          <div className="text-red-500 text-xs mb-2">{errors.validation.description[0]}</div>
        )}
        <input
          id="description"
          type="text"
          value={descriptionText}
          onChange={e => setDescription(e.target.value)}
          className={inputClass}
          autoComplete="off"
          required
        />
      </div>
      <div className={blockClass}>
        <label className={labelClass} htmlFor="schemaPath">Long Description</label>
        {errors && errors.validation?.longDescription && (
          <div className="text-red-500 text-xs mb-2">{errors.validation.longDescription[0]}</div>
        )}
        <input
          id="longDescription"
          type="text"
          value={longDescriptionText}
          onChange={e => setLongDescription(e.target.value)}
          className={inputClass}
          autoComplete="off"
          required
        />
      </div>
      <div className="grid grid-cols-2 gap-2">
        <div className={blockClass}>
          <label className={labelClass} htmlFor="path">Path</label>
          {errors && errors.validation?.path && (
            <div className="text-red-500 text-xs mb-2">{errors.validation.path[0]}</div>
          )}
          <input
            id="path"
            type="text"
            value={pathText}
            onChange={e => setPath(e.target.value)}
            className={inputClass}
            autoComplete="off"
            required
          />
        </div>
        <div className={blockClass}>
          <label className={labelClass} htmlFor="schemaPath">Schema Path</label>
          {errors && errors.validation?.schemaPath && (
            <div className="text-red-500 text-xs mb-2">{errors.validation.schemaPath[0]}</div>
          )}
          <input
            id="schemaPath"
            type="text"
            value={schemaPathText}
            onChange={e => setSchemaPath(e.target.value)}
            className={inputClass}
            autoComplete="off"
            required
          />
        </div>
      </div>
      <div className={blockClass}>
        <label className={labelClass} htmlFor="schemaPath">Config Paths</label>
        {errors && errors.validation?.configPaths && (
          <div className="text-red-500 text-xs mb-2">{errors.validation.configPaths[0]}</div>
        )}
        <input
          id="configPaths"
          type="text"
          value={configPathsText}
          onChange={e => setConfigPaths(e.target.value)}
          className={inputClass}
          autoComplete="off"
        />
      </div>
      <div className="flex flex-wrap gap-x-4 gap-y-1 mb-1">
        <div className={checkBlockClass}>
          <label className={labelClass} htmlFor="private">Private</label>
          <input
            id="private"
            type="checkbox"
            checked={isPrivateText}
            onChange={e => setIsPrivate(e.target.checked)}
          />
        </div>
        <div className={checkBlockClass}>
          <label className={labelClass} htmlFor="active">Active</label>
          <input
            id="active"
            type="checkbox"
            checked={activeText}
            onChange={e => setActive(e.target.checked)}
          />
        </div>
      </div>
      <div className={blockClass}>
        <label className={labelClass} htmlFor="upstreamPipelineVersions">Genome Pipeline Versions</label>
        <select
          id="upstreamPipelineVersions" multiple
          value={upstreamPipelineVersionsText}
          onChange={handleSelectChange}
          className="text-2xs outline-none border"
        >
          {allGenomeVersions.map(version => (
            <option key={version.id} value={version.id}>{version.pipelineName} {version.name}</option>
          ))}
        </select>
      </div>
      <div className="flex gap-2 text-xs mt-1">
        <Button type="submit" className="btn-primary text-white py-1 px-2" loading={loading}>Save</Button>
        <Button type="button" className="btn-secondary text-[#54618D] py-1 px-2" onClick={() => setShow(false)}>Cancel</Button>
      </div>
    </form>
  );
};

PipelineVersionForm.propTypes = {
  pipelineVersion: PropTypes.object,
  pipelineId: PropTypes.string.isRequired,
  setShow: PropTypes.func.isRequired,
  allGenomeVersions: PropTypes.array.isRequired,
};

export default PipelineVersionForm;